import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import PPMMatrix from './PPMMatrix';
import Step1 from './Feature2Step1';
import Step2 from './Feature2Step2';
import Step3 from './Feature2Step3';

const SHEET_ID = "14bVK--dgREt2Pl37jtaaERko3_BQJxTf1VdO3s2bVHg"; // Replace with your Google Sheet ID
const API_KEY = "AIzaSyCNWjZ51dL4OVE60Jf8Gie1j4wXjKXX7dM"; // Replace with your Google API Key
const RANGE = "Sheet1!A1:T20"; // Adjust to match the actual sheet name and range

function Feature2() {
	const [isMounted, setIsMounted] = useState(false);
	const [formData, setFormData] = useState({
		yourTeam: [
			...Array.from({ length: 6 }, (_, i) => ({ skillLevel: i + 2, quantity: 0, plusMinus: 'n', completeMatches: 0 })), // Initializes 6 skill levels
			{ skillLevel: 0, quantity: 0, plusMinus: '', completeMatches: 0 }, // Adds forfeit entry
		],
		theOpponent: [
			...Array.from({ length: 6 }, (_, i) => ({ skillLevel: i + 2, quantity: 0, plusMinus: 'n', completeMatches: 0 })), // Initializes 6 skill levels
			{ skillLevel: 0, quantity: 0, plusMinus: '', completeMatches: 0 }, // Adds forfeit entry
		],
		selectedTeam: '', // Stores which team is selected ("yourTeam" or "theOpponent")
		matchUps: [], // This will be dynamically populated based on step 1 data
	});
	const [currentStep, setCurrentStep] = useState(0);

	const steps = [
		{ name: 'Step 1', component: Step1 },
		{ name: 'Step 2', component: Step2 },
		{ name: 'Step 3', component: Step3 },
		//{ name: 'Review', component: Review },
	];

	const [ppmMatrixData, setPpmMatrixData] = useState(null);

	const fetchSpreadsheetData = async () => {
		const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${RANGE}?key=${API_KEY}`;
	
		try {
			const response = await fetch(url);
			const data = await response.json();
	/*
			if (data.values) {
				// Convert the raw data into an associative array
				const headers = data.values[0].slice(1); // First row (excluding first column)
				const matrix = {};
	
				for (let i = 1; i < data.values.length; i++) {
					const rowLabel = data.values[i][0]; // First column as row label
					matrix[rowLabel] = {};
	
					for (let j = 1; j < data.values[i].length; j++) {
						matrix[rowLabel][headers[j - 1]] = parseFloat(data.values[i][j]) || 0; // Convert to decimal
					}
				}
	
				return matrix;
			}
			*/
			if (data.values) {
				return data.values;
			}
		} catch (error) {
			console.error("Error fetching Google Sheet data:", error);
		}
	
		return null;
	};

	useEffect(() => {

		if (isMounted) {
			const loadData = async () => {
				const result = await fetchSpreadsheetData();
				setPpmMatrixData(result);
			};
			loadData();
		} else {
			setIsMounted(true);
		}

	}, [isMounted]);

	// This function will be responsible for both updating form data and matchups
	function handleFormChange(updatedData) {
		setFormData((prevFormData) => ({
			...prevFormData, // Keep the existing data
			...updatedData,  // Merge in updated data
		}));
	}

	const handleGoToStep = (stepIndex) => {
		setCurrentStep(stepIndex);
	};

	const CurrentComponent = steps[currentStep].component;

	return (
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">

			<div className="panel large pt-20l-10s">
				<div className="grid-x bb1-333 pb-5 hide-for-small-only">
					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-source-sans font-x-large font-weight-700 txt-333 uppercase">Feature 2</div>
					</div>
					<div className="large-6 medium-6 small-6 cell text-right">
						<div className="font-source-sans font-x-large font-weight-700 txt-333 uppercase">{steps[currentStep].name}</div>
					</div>
				</div>
				<div className="grid-x bb1-333 pb-5 show-for-small-only">
					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-source-sans font-large font-weight-700 txt-333 uppercase">Feature 2</div>
					</div>
					<div className="large-6 medium-6 small-6 cell text-right">
						<div className="font-source-sans font-large font-weight-700 txt-333 uppercase">{steps[currentStep].name}</div>
					</div>
				</div>
			</div>

			<div className="panel large">
				<CurrentComponent formData={formData} onChange={handleFormChange} ppmMatrixData={ppmMatrixData}/>
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-center">

						<div className="pt-20l-10s">
							{steps.map((step, index) => (
								index === currentStep ? (
									<span key={index} className="font-source-sans font-large font-weight-600 uppercase plr-15">
										{step.name}
									</span>
								) : (
									<Link key={index} onClick={() => handleGoToStep(index)} className="font-source-sans font-large font-weight-600 uppercase plr-15">
										{step.name}
									</Link>
								)
							))}
						</div>
					</div>
				</div>
			</div>


		</div>
	);
}

export default Feature2;