import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Step1 from './Feature1Step1';
import Step2 from './Feature1Step2';
import Step3 from './Feature1Step3';

function Feature1() {
	const [formData, setFormData] = useState({
		step1: {
			yourTeam: [
				...Array.from({ length: 6 }, (_, i) => ({ skillLevel: i + 2, quantity: '', minusPlus: '' })), // Initializes 6 skill levels
				{ skillLevel: 0, quantity: '' }, // Adds forfeit entry
			],
			yourOpponent: [
				...Array.from({ length: 6 }, (_, i) => ({ skillLevel: i + 2, quantity: '', minusPlus: '' })), // Initializes 6 skill levels
				{ skillLevel: 0, quantity: '' }, // Adds forfeit entry
			],
		},
		step2: {
			selectedTeam: '', // Stores which team is selected ("yourTeam" or "yourOpponent")
		},
		step3: {
			matchUps: [], // This will be dynamically populated based on step 1 data
		},
	});
	const [currentStep, setCurrentStep] = useState(0);

	const steps = [
		{ name: 'Step 1', component: Step1 },
		{ name: 'Step 2', component: Step2 },
		{ name: 'Step 3', component: Step3 },
		//{ name: 'Review', component: Review },
	];

	const handleChange = (stepKey, updatedData, index, team) => {
		setFormData((prevData) => {
			let updatedStepData;

			if (isStep3) {
				// For step 3, add the new data to the matchUps array
				updatedStepData = {
					...prevData[stepKey],
					matchUps: [...prevData[stepKey].matchUps, updatedData],
				};
			} else if (team === null && index === null) {
				// For non-array updates (e.g., Step 2)
				updatedStepData = {
					...prevData[stepKey],
					...updatedData,
				};
			} else if (Array.isArray(prevData[stepKey][team])) {
				// For array updates (e.g., Step 1 team data)
				updatedStepData = {
					...prevData[stepKey],
					[team]: prevData[stepKey][team].map((item, i) =>
						i === index ? { ...item, ...updatedData } : item
					),
				};
			} else {
				// Handle other cases where team is specified but not an array
				updatedStepData = {
					...prevData[stepKey],
					[team]: {
						...prevData[stepKey][team],
						...updatedData,
					},
				};
			}

			return {
				...prevData,
				[stepKey]: updatedStepData,
			};
		});
	};

	//handles the step navigation
	const handleNext = () => {
		if (currentStep < steps.length - 1) {
			setCurrentStep(prev => prev + 1);
		}
	};

	const handlePrevious = () => {
		if (currentStep > 0) {
			setCurrentStep(prev => prev - 1);
		}
	};

	const handleGoToStep = (stepIndex) => {
		setCurrentStep(stepIndex);
	};

	const CurrentComponent = steps[currentStep].component;
	const isStep3 = steps[currentStep].name === 'Step 3';
	const isReview = steps[currentStep].name === 'Review';

	return (
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">

			<div className="panel large pt-20l-10s">
				<div className="grid-x bb1-333 pb-5 hide-for-small-only">
					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-source-sans font-x-large font-weight-700 txt-333 uppercase">Feature 1</div>
					</div>
					<div className="large-6 medium-6 small-6 cell text-right">
						<div className="font-source-sans font-x-large font-weight-700 txt-333 uppercase">{steps[currentStep].name}</div>
					</div>
				</div>
				<div className="grid-x bb1-333 pb-5 show-for-small-only">
					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-source-sans font-large font-weight-700 txt-333 uppercase">Feature 1</div>
					</div>
					<div className="large-6 medium-6 small-6 cell text-right">
						<div className="font-source-sans font-large font-weight-700 txt-333 uppercase">{steps[currentStep].name}</div>
					</div>
				</div>
			</div>

			<div className="panel large">
				<CurrentComponent
					formData={formData[steps[currentStep].name.toLowerCase().replace(' ', '')]}
					{...(!isReview && {
						onChange: (data, index, team) =>
							handleChange(
								steps[currentStep].name.toLowerCase().replace(' ', ''),
								data,
								index,
								team
							),
					})}
					{...(isStep3 && {
						teamData: {
							yourTeam: formData.step1.yourTeam,
							yourOpponent: formData.step1.yourOpponent,
						},
						selectedTeam: formData.step2.selectedTeam,
					})}
				/>
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-center">

						<div className="pt-20l-10s">
							{steps.map((step, index) => (
								index === currentStep ? (
									<span key={index} className="font-source-sans font-large font-weight-500 plr-15">
										{step.name}
									</span>
								) : (
									<Link key={index} onClick={() => handleGoToStep(index)} className="font-source-sans font-large font-weight-500 plr-15">
										{step.name}
									</Link>
								)
							))}
						</div>
					</div>
				</div>
			</div>


		</div>
	);
}

export default Feature1;