// Step1.js
import React from 'react';

//function Feature2Step2({ checkedStates, handleCheckboxChange }) {
function Feature2Step2({ formData, onChange, ppmMatrixData }) {

  const handleRadioChange = (event) => {
    if (formData.matchUps.length > 0) {
        alert('The first match has already started !');
        return;
    }

    const updatedFormData = {
      ...formData,
      selectedTeam: event.target.value,
    };
    onChange(updatedFormData);
  };

  return (
    <>
      <div className="grid-x">
        <div className="large-12 medium-12 small-12 cell text-center ptb-20 ">
          <div className="font-source-sans font-large font-weight-600">Which  team put up first player?</div>
        </div>
      </div>
      <div className="grid-x b1-ccc">
        <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-eee">
          <div className="font-source-sans font-standard font-weight-600">Your Team</div>
        </div>
        <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-fafafa">
          <div className="font-source-sans font-standard font-weight-600">The Opponent</div>
        </div>
        <div className="large-6 medium-6 small-6 cell text-center p-20 bg-eee">
          <input
            type="radio"
            name="teamSelection"
            value="yourTeam"
            checked={formData.selectedTeam === 'yourTeam'}
            onChange={(event) => handleRadioChange(event)}
            className="custom-radio"
          />
        </div>
        <div className="large-6 medium-6 small-6 cell text-center p-20 bg-fafafa">
          <input
            type="radio"
            name="teamSelection"
            value="theOpponent"
            checked={formData.selectedTeam === 'theOpponent'}
            onChange={(event) => handleRadioChange(event)}
            className="custom-radio"
          />
        </div>
      </div>
    </>
  );
}

export default Feature2Step2;