import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';
import PayPalButton from './PayPalButton';

import swal from 'sweetalert';

import subscription_icon from '../../assets/frontend/images/subscription_icon.png';
import edit_icon from '../../assets/frontend/images/edit_icon.png';
import logout_icon from '../../assets/frontend/images/logout_icon.png';

function Account(){
	
	const navHistory = useNavigate();
	
	// using hooks
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
	const [subscriptionInfo, setSubscriptionInfo] = useState({
		profile_pay_type: '',
		profile_subscription: '',
		payment_paid_plan: '',
		payment_paid_status: '',
		payment_paid_amount: 0,
		payment_paid_start_date: '',
		payment_paid_end_date: '',
		payment_cancelled_plan: 0,
		payment_cancelled_status: '',
		payment_cancelled_amount: 0,
		payment_cancelled_date: '',
	});
	const [subscriptionOption, setSubscriptionOption] = useState({
		selected: false,
		yearly_payment_amount: 0,
		monthly_payment_amount: 0,
		vip_yearly_payment_amount: 0,
		vip_monthly_payment_amount: 0,
	});
    const [isCancelling, setIsCancelling] = useState(false);

	// Initial call for user list items
	useEffect(() => {
	
		if(!AuthUtility.isLoggedIn()){
				
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
				
			navHistory('/');
		}
		
		setIsLoading(true);
		
		if (isMounted) {
			
			console.log('[Account - useEffect] mounted');

			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchData = async () => {
				try {
					const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
					await getBearerToken();
					const response = await makeRequestWithHeaders('get', '/api/subscription_info', null, {});

					setApiData(response);
				} catch (error) {
					handleApiError(error);
				}
			};
		
			fetchData();
			console.log('Account subscription amount:', subscriptionInfo.payment_amount);
			
		}else {
			setIsMounted(true);
		}
		
	}, [isMounted]);

	function setApiData(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK
				if(response.data.profile.pay_type === 'payee' || response.data.profile.pay_type === 'vip'){
					if(response.data.profile.subscription === 'subscribed' && response.data.payment_paid.status === 'paid'){
						setSubscriptionInfo({...subscriptionInfo, 
							profile_pay_type: response.data.profile.pay_type, profile_subscription: response.data.profile.subscription, 
							payment_paid_plan: response.data.payment_paid.plan, payment_paid_status: response.data.payment_paid.status, payment_paid_amount: response.data.payment_paid.amount,
							payment_paid_start_date: response.data.payment_paid.start_date, payment_paid_end_date: response.data.payment_paid.end_date, 
						});
					}else{
						setSubscriptionOption({...subscriptionOption, yearly_payment_amount: response.data.subscription_yearly_payment_amount, monthly_payment_amount: response.data.subscription_monthly_payment_amount,
							vip_yearly_payment_amount: response.data.subscription_vip_yearly_payment_amount, vip_monthly_payment_amount: response.data.subscription_vip_monthly_payment_amount,
						});

						if(response.data.profile.subscription == 'cancelled' && response.data.payment_paid && response.data.payment_cancelled){
							setSubscriptionInfo({...subscriptionInfo, profile_pay_type: response.data.profile.pay_type, profile_subscription: response.data.profile.subscription, 
								payment_paid_plan: response.data.payment_paid.plan, payment_paid_amount: response.data.payment_paid.amount,
								payment_paid_start_date: response.data.payment_paid.start_date, payment_paid_end_date: response.data.payment_paid.end_date,
								payment_cancelled_plan: response.data.payment_cancelled.plan, payment_cancelled_amount: response.data.payment_cancelled.amount,
								payment_cancelled_date: response.data.payment_cancelled.date
							});
						}else{
							setSubscriptionInfo({...subscriptionInfo, profile_pay_type: response.data.profile.pay_type});
						}
						
					}
				}else if(response.data.profile.pay_type === 'free'){

				}
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				//user payment not found
				setSubscriptionInfo({...subscriptionInfo, profile_pay_type: response.data.profile.pay_type});
				setSubscriptionOption({...subscriptionOption, yearly_payment_amount: response.data.subscription_yearly_payment_amount, monthly_payment_amount: response.data.subscription_monthly_payment_amount,
					vip_yearly_payment_amount: response.data.subscription_vip_yearly_payment_amount, vip_monthly_payment_amount: response.data.subscription_vip_monthly_payment_amount,
				});
			}else{//more errors
			}
		}

		setIsLoading(false);
	}
	function handleApiError(error){
		console.log('[Account - useEffect] error: ',error + ' back-end api call error');

		setIsLoading(false);
	}

	const handleSubscriptionOption = (subscription_plan, profile_pay_type) => {
		
		var subscription_plan_amount = 0;
		if(subscription_plan == 'yearly'){
			if(profile_pay_type == 'payee'){
				subscription_plan_amount = subscriptionOption.yearly_payment_amount;
			}else if(profile_pay_type == 'vip'){
				subscription_plan_amount = subscriptionOption.vip_yearly_payment_amount;
			}
		}else if(subscription_plan == 'monthly'){
			if(profile_pay_type == 'payee'){
				subscription_plan_amount = subscriptionOption.monthly_payment_amount;
			}else if(profile_pay_type == 'vip'){
				subscription_plan_amount = subscriptionOption.vip_monthly_payment_amount;
			}
		}
		setSubscriptionInfo({...subscriptionInfo, payment_paid_plan: subscription_plan, payment_paid_amount: subscription_plan_amount});
		setSubscriptionOption({...subscriptionOption, selected: true});
	};

    const handleSubsriptionPaymentComplete = (data) => {
		setSubscriptionInfo({...subscriptionInfo, profile_pay_type: data.profile.pay_type, profile_subscription: data.profile.subscription, 
			payment_paid_plan: data.payment_paid.plan, payment_paid_status: data.payment_paid.status, payment_paid_amount: data.payment_paid.amount, 
			payment_paid_start_date: data.payment_paid.start_date, payment_paid_end_date: data.payment_paid.end_date,
			payment_cancelled_plan: '', payment_cancelled_status: '', payment_cancelled_amount: '', payment_cancelled_date: '',
		});
		setSubscriptionOption({...subscriptionOption, 
			selected: false, yearly_payment_amount: 0, monthly_payment_amount: 0, vip_yearly_payment_amount: 0, vip_monthly_payment_amount: 0,
		});

		AuthUtility.setSubscriptionData(data.payment_paid.plan, data.payment_paid.start_date, data.payment_paid.end_date, data.payment_paid.status, data.profile.subscription);
    };

	const handleSubscriptionCancel = (event) => {
		event.preventDefault();

		setIsCancelling(true);
			
		console.log('[Account - handleSubscriptionCancel] start');

		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
			auth_api = localStorage.getItem('auth_api');
		}

		const fetchDataSubscriptionCancel = async () => {
			try {
				const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
				await getBearerToken();
				const response = await makeRequestWithHeaders('get', '/api/subscription_cancel', null, {});

				setApiDataSubscriptionCancel(response);
			} catch (error) {
				handleApiErrorSubscriptionCancel(error);
			}
		};
	
		fetchDataSubscriptionCancel();
		console.log('[Account - handleSubscriptionCancel] end');
	}

	function setApiDataSubscriptionCancel(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK
				if(response.data.profile.pay_type === 'payee' || response.data.profile.pay_type === 'vip'){
					if(response.data.profile.subscription === 'cancelled'){
						setSubscriptionOption({...subscriptionOption, yearly_payment_amount: response.data.subscription_yearly_payment_amount, monthly_payment_amount: response.data.subscription_monthly_payment_amount,
							vip_yearly_payment_amount: response.data.subscription_vip_yearly_payment_amount, vip_monthly_payment_amount: response.data.subscription_vip_monthly_payment_amount,
						});

						setSubscriptionInfo({...subscriptionInfo, profile_pay_type: response.data.profile.pay_type, profile_subscription: response.data.profile.subscription, 
							payment_paid_plan: response.data.payment_paid.plan, payment_paid_amount: response.data.payment_paid.amount,
							payment_paid_start_date: response.data.payment_paid.start_date, payment_paid_end_date: response.data.payment_paid.end_date,
							payment_cancelled_plan: response.data.payment_cancelled.plan, payment_cancelled_amount: response.data.payment_cancelled.amount,
							payment_cancelled_date: response.data.payment_cancelled.date,
						});
						
						AuthUtility.setSubscriptionData(response.data.payment_paid.plan, response.data.payment_paid.start_date, response.data.payment_paid.end_date,
							response.data.payment_paid.status, response.data.profile.subscription);
							//setSubscriptionData: (subscription_plan, subscription_start_date, subscription_end_date, subscription_paid_status, profile_subscription) => {
						}
				}else if(response.data.profile.pay_type === 'free'){

				}
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				//user payment not found
				setSubscriptionInfo({...subscriptionInfo, profile_pay_type: response.data.profile.pay_type});
				setSubscriptionOption({...subscriptionOption, yearly_payment_amount: response.data.subscription_yearly_payment_amount, monthly_payment_amount: response.data.subscription_monthly_payment_amount,
					vip_yearly_payment_amount: response.data.subscription_vip_yearly_payment_amount, vip_monthly_payment_amount: response.data.subscription_vip_monthly_payment_amount,
				});
			}else{//more errors
			}
		}

		setIsCancelling(false);
	}
	function handleApiErrorSubscriptionCancel(error){
		console.log('[Account - handleSubscriptionCancel] error: ',error + ' back-end api call error');

		setIsCancelling(false);
	}

	const logoutSubmit = (event) => {
		event.preventDefault();

		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
			auth_api = localStorage.getItem('auth_api');
		}

		const fetchData = async () => {
			try {
			  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
			  await getBearerToken();
			  const response = await makeRequestWithHeaders('get', '/api/logout', null, {});

			  setApiDataLogout(response);
			} catch (error) {
			  handleApiErrorLogout(error);
			}
		};
	  
		fetchData();
	}
    function setApiDataLogout(response){
		
		if(response && response.data){
			if(response.data.status === 200){//success

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				//redirect to login page
				navHistory('/login');
			}else if(response.data.status === 401){//user was not logged in

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
					
				navHistory('/login');
			}else{
				console.log('Error 40X: api call failed');
				
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				navHistory('/login');
			}
		}
	}
    function handleApiErrorLogout(error){
		//csrf-cookie is outdated
		console.log('logoutSubmit error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
		
		navHistory('/login');
    }
    
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-source-sans font-x-large font-weight-700 txt-333 uppercase bb1-333 pb-5">Account</div>
					</div>
				</div>
			</div>
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">

					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-source-sans font-large font-weight-700 txt-333 underline uppercase">Subscription</div>
					</div>

					{subscriptionInfo.profile_subscription == 'subscribed' ? (
						isCancelling ? (
							<div className="large-6 medium-6 small-6 cell text-right">
								<LoadingSpinner paddingClass="none" sizeClass="small"/>
							</div>
						):(
							<div className="large-6 medium-6 small-6 cell text-right">
								<Link to="" onClick={handleSubscriptionCancel} className="button icon auto">Cancel</Link>
							</div>
						)
					):(
						<div className="large-6 medium-6 small-6 cell text-right">
						</div>
					)
					}

					{isLoading ? (
					<div className="large-12 medium-12 small-6 cell text-center">
					<LoadingSpinner paddingClass="pt-10" />
					</div>
					) : (
						subscriptionInfo.profile_subscription == '' && subscriptionInfo.payment_paid_status == '' ? (
							subscriptionInfo.payment_paid_plan == '' && subscriptionInfo.payment_paid_amount == 0 ? (
								<div className="large-12 medium-12 small-12 cell text-center pt-10">
									<div className="font-source-sans font-weight-600 txt-333 text-center">
										Please select your subscription option
										{process.env.REACT_APP_ENV == 'development' &&
										<div className="text-center pt-10 font-medium txt-red">The subscriptions are in test mode so you should see values of $1.00 and $1.01.</div>
										}
										{subscriptionInfo.profile_pay_type == 'payee' &&
										<div className="text-center pt-10">
										<Link to="" onClick={() => handleSubscriptionOption('monthly', subscriptionInfo.profile_pay_type)} className="button">${subscriptionOption.monthly_payment_amount} Monthly</Link>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Link to="" onClick={() => handleSubscriptionOption('yearly', subscriptionInfo.profile_pay_type)} className="button">${subscriptionOption.yearly_payment_amount} Yearly</Link>
										</div>
										}
										{subscriptionInfo.profile_pay_type == 'vip' &&
										<div className="text-center pt-10">
										<Link to="" onClick={() => handleSubscriptionOption('monthly', subscriptionInfo.profile_pay_type)} className="button">${subscriptionOption.vip_monthly_payment_amount} Monthly</Link>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Link to="" onClick={() => handleSubscriptionOption('yearly', subscriptionInfo.profile_pay_type)} className="button">${subscriptionOption.vip_yearly_payment_amount} Yearly</Link>
										</div>
										}
									</div>
								</div>
							) : (
								<>
								<div className="large-12 medium-12 small-12 cell text-center pt-10">
									<div className="font-source-sans font-weight-600 txt-333 text-center">
										Please select your payment option for (${subscriptionInfo.payment_paid_amount} {subscriptionInfo.payment_paid_plan}) subscription.
									</div>
								</div>
								<div className="large-3 medium-3 small-12 cell text-center pt-10 hide-for-small-only"></div>
								<div className="large-6 medium-6 small-12 cell text-center pt-10">
									<PayPalButton subscriptionInfo={subscriptionInfo} onSubscriptionPaymentComplete={handleSubsriptionPaymentComplete}/>
								</div>
								<div className="large-3 medium-3 small-12 cell text-center pt-10 hide-for-small-only"></div>
								</>
							)
						) : (
							subscriptionInfo.profile_subscription == 'cancelled' ? (
								<>
								<div className="large-12 medium-12 small-12 cell text-left pt-10 pr-10">
									<table className="unstriped unbordered mb-0">
										<tbody>
										<tr><td className="font-source-sans font-weight-400 width-100px pb-10">Plan:</td><td className="font-source-sans font-weight-600 pb-10">{subscriptionInfo.payment_cancelled_plan}{subscriptionInfo.profile_pay_type == 'vip' ? ' (vip)' : ''} - cancelled {subscriptionInfo.payment_cancelled_date}</td></tr>
										<tr><td className="font-source-sans font-weight-400 width-100px pb-10">Amount:</td><td className="font-source-sans font-weight-600 pb-10">${subscriptionInfo.payment_cancelled_amount}</td></tr>
										<tr><td className="font-source-sans font-weight-400 width-100px pb-10">Valid:</td><td className="font-source-sans font-weight-600 pb-10">{subscriptionInfo.payment_paid_start_date} - {subscriptionInfo.payment_paid_end_date}</td></tr>
										</tbody>
									</table>
								</div>
								{!subscriptionOption.selected ? (
									<div className="large-12 medium-12 small-12 cell text-center ptb-20 bt1-ccc">
										<div className="font-source-sans font-weight-600 txt-333 text-center">
											Please select your subscription option
											{subscriptionInfo.profile_pay_type == 'payee' &&
											<div className="text-center pt-10">
											<Link to="" onClick={() => handleSubscriptionOption('monthly', subscriptionInfo.profile_pay_type)} className="button">${subscriptionOption.monthly_payment_amount} Monthly</Link>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<Link to="" onClick={() => handleSubscriptionOption('yearly', subscriptionInfo.profile_pay_type)} className="button">${subscriptionOption.yearly_payment_amount} Yearly</Link>
											</div>
											}
											{subscriptionInfo.profile_pay_type == 'vip' &&
											<div className="text-center pt-10">
											<Link to="" onClick={() => handleSubscriptionOption('monthly', subscriptionInfo.profile_pay_type)} className="button">${subscriptionOption.vip_monthly_payment_amount} Monthly</Link>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<Link to="" onClick={() => handleSubscriptionOption('yearly', subscriptionInfo.profile_pay_type)} className="button">${subscriptionOption.vip_yearly_payment_amount} Yearly</Link>
											</div>
											}
											{process.env.REACT_APP_ENV == 'development' &&
											<div className="text-center pt-10 font-medium txt-red">The subscriptions are in test mode so you should see values of $1.00 or $1.01.</div>
											}
										</div>
									</div>
								):(
									<>
									<div className="large-12 medium-12 small-12 cell text-center pt-10 bt1-ccc">
										<div className="font-source-sans font-weight-600 txt-333 text-center">
											Please select your payment option for (${subscriptionInfo.payment_paid_amount} {subscriptionInfo.payment_paid_plan}) subscription.
										</div>
									</div>
									<div className="large-3 medium-3 small-12 cell text-center pt-10 hide-for-small-only"></div>
									<div className="large-6 medium-6 small-12 cell text-center pt-10">
										<PayPalButton subscriptionInfo={subscriptionInfo} onSubscriptionPaymentComplete={handleSubsriptionPaymentComplete}/>
									</div>
									<div className="large-3 medium-3 small-12 cell text-center pt-20 hide-for-small-only"></div>
									</>
								)}
								</>
							):(
								<div className="large-12 medium-12 small-12 cell text-left pt-10 pr-10">
									<table className="unstriped unbordered mb-0">
										<tbody>
										<tr><td className="font-source-sans font-weight-400 width-100px pb-10">Plan:</td><td className="font-source-sans font-weight-600 pb-10">{subscriptionInfo.payment_paid_plan}{subscriptionInfo.profile_pay_type == 'vip' ? ' (vip)' : ''}</td></tr>
										<tr><td className="font-source-sans font-weight-400 width-100px pb-10">Amount:</td><td className="font-source-sans font-weight-600 pb-10">${subscriptionInfo.payment_paid_amount}</td></tr>
										<tr><td className="font-source-sans font-weight-400 width-100px">Valid:</td><td className="font-source-sans font-weight-600">{subscriptionInfo.payment_paid_start_date} - {subscriptionInfo.payment_paid_end_date}</td></tr>
										</tbody>
									</table>
								</div>
							)
						)
					)}

				</div>
			</div>

			<div className="panel large pt-20l-10s">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">

					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-source-sans font-large font-weight-700 txt-333 underline uppercase">Profile</div>
					</div>

					<div className="large-6 medium-6 small-6 cell text-right">
						<Link to="../profile" className="button icon"><img src={edit_icon} alt="profile"/></Link>
					</div>

					<div className="large-12 medium-12 small-12 cell text-left pt-10">
						<table className="unstriped unbordered mb-0">
							<tbody>
							<tr><td className="font-source-sans font-weight-400 width-100px pb-10">Name:</td><td className="font-source-sans font-weight-600 pb-10">{localStorage.getItem('auth_first_name') + ' ' + localStorage.getItem('auth_last_name')}</td></tr>
							<tr><td className="font-source-sans font-weight-400 width-100px pb-10">Email:</td><td className="font-source-sans font-weight-600 pb-10">{localStorage.getItem('auth_email')}</td></tr>
							<tr><td className="font-source-sans font-weight-400 width-100px pb-10">Role:</td><td className="font-source-sans font-weight-600 pb-10">{localStorage.getItem('auth_role')}{subscriptionInfo.profile_pay_type == 'vip' ? ' (vip)' : ''}</td></tr>
							<tr><td className="font-source-sans font-weight-400 width-100px">Password:</td><td className="font-source-sans font-weight-600 ">********</td></tr>
							</tbody>
						</table>
					</div>
					
				</div>
			</div>
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">

					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-source-sans font-large font-weight-700 txt-333 underline uppercase">Logout</div>
					</div>

					<div className="large-6 medium-6 small-6 cell text-right">
						<Link onClick={logoutSubmit} className="button icon"><img src={logout_icon} alt="logout"/></Link>
					</div>
					
				</div>
			</div>

		</div>
	);
}

export default Account;