import React, { useState, useEffect } from 'react';

function Feature2Step1({ formData, onChange, ppmMatrixData }) {
    const [teamTotals, setTeamTotals] = useState({ yourTeam: 0, theOpponent: 0 });

    useEffect(() => {

        const calculateTeamTotal = (teamData) => {
            return teamData.reduce((sum, item, index) => sum + Number(item.skillLevel || 0) * Number(item.quantity || 0), 0);
        };

        const yourTeamTotal = calculateTeamTotal(formData.yourTeam);
        const theOpponentTotal = calculateTeamTotal(formData.theOpponent);

        setTeamTotals({ yourTeam: yourTeamTotal, theOpponent: theOpponentTotal });

        if (yourTeamTotal > 23) {
            alert('Your team total exceeds 23!');
        }
        if (theOpponentTotal > 23) {
            alert('Your opponent total exceeds 23!');
        }
    }, [formData]);

    const handleSelectChange = (team, index, event) => {
        if (team === 'yourTeam' || team === 'theOpponent') {

            const calculateTeamPlayerTotal = (teamData) => {
                return teamData.reduce((sum, item, indx) => {
                    // Subtract the current quantity if index matches indx, then add the new value.
                    return sum + (indx === index ? 0 : Number(item.quantity || 0));
                }, 0);
            };

            const calculateTeamTotal = (teamData) => {
                return teamData.reduce((sum, item, indx) => {
                    // Subtract the current quantity if index matches indx, then add the new value.
                    return sum + (indx === index ? 0 : Number(item.skillLevel || 0) * Number(item.quantity || 0));
                }, 0);
            };

            const teamType = team === 'yourTeam' ? 'Your Team' : 'Your Opponent';
            const previousQuantity = Number(formData[team][index].quantity);
            const newQuantity = Number(event.target.value);
            const skillLevel = Number(formData[team][index].skillLevel);

            var teamPlayerTotal = 0;
            var teamTotal = 0;
            var quantityViolation = false;
            if (newQuantity > 0) {//not a forfeit
                teamPlayerTotal = calculateTeamPlayerTotal(formData[team]) + newQuantity;
                teamTotal = calculateTeamTotal(formData[team]) + skillLevel * newQuantity;
            }
            if (teamPlayerTotal > 5) {
                alert('Total quantity of players on ' + teamType + ' exceeds 5!\n\n' + 'Try decreasing the quantity of players at another skill before increasing the quantity of players at skill=' + skillLevel);

            }
            if (teamTotal > 23) {
                alert('Calculated total for ' + teamType + ' exceeds 23!');
            }
            if (newQuantity < Number(formData[team][index].completeMatches)) {
                quantityViolation = true;
                alert(teamType + '\n' + formData[team][index].completeMatches + ' player(s) @ skill=' + formData[team][index].skillLevel + ' completed their match');
            }
            if (!quantityViolation && teamPlayerTotal <= 5 && teamTotal <= 23) {
                let availableInstances = [...Array(previousQuantity).keys()].slice(0, newQuantity);
                let usedInstances = [];
                // Reassign instances in matchUps for the affected team
                const updatedMatchUps = [...formData.matchUps];
                if (newQuantity < previousQuantity) {
                    for (let matchIndex = updatedMatchUps.length - 1; matchIndex >= 0; matchIndex--) {
                        const match = updatedMatchUps[matchIndex];
                        const teamPlayer = team === 'yourTeam' ? match.yourTeamPlayer : match.theOpponentPlayer;
                        if (teamPlayer.skillLevel === skillLevel) {
                            const currentInstance = teamPlayer.instance;
                            usedInstances.push(currentInstance);
                        }
                    }

                    usedInstances = usedInstances.sort((a, b) => a - b);

                    // Create the instance mapping
                    const instanceMapping = {};
                    for (let i = availableInstances.length - 1, j = usedInstances.length - 1; i >= 0 && j >= 0; i--, j--) {
                        instanceMapping[usedInstances[j]] = availableInstances[i];
                    }

                    // Update matchUps with the new instance values
                    updatedMatchUps.forEach(match => {
                        const teamPlayer = team === 'yourTeam' ? match.yourTeamPlayer : match.theOpponentPlayer;
                        if (teamPlayer && instanceMapping[teamPlayer.instance] !== undefined) {
                            teamPlayer.instance = instanceMapping[teamPlayer.instance];
                        }
                    });

                }

                // Update formData
                const updatedFormData = {
                    ...formData,
                    [team]: formData[team].map((item, indx) =>
                        indx === index
                            ? { ...item, quantity: newQuantity }
                            : item
                    ),
                    matchUps: updatedMatchUps,
                };
                onChange(updatedFormData);
            }
        } else {
            console.log(team + ' was NOT FOUND');
        }
    };

    const generateMessages = (teamName, teamData) => {
        // Filter players with completeMatches > 0
        return teamData
            .filter((item) => item.completeMatches > 0)
            .map((item) => (
                <React.Fragment key={`${teamName}-${item.skillLevel}`}>
                    <div className="font-source-sans font-standard font-weight-500 pt-5">
                        {item.completeMatches} player(s) @ skill={item.skillLevel}
                    </div>
                </React.Fragment>
            ));
    };

    return (
        <div className="grid-x b1-ccc">
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-eee">
                <div className="font-source-sans font-standard font-weight-600">Your Team</div>
            </div>
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-fafafa">
                <div className="font-source-sans font-standard font-weight-600">The Opponent</div>
            </div>
            <div className="large-3 medium-3 small-3 cell text-center p-5 bg-eee">
                <div className="font-source-sans font-standard font-weight-600">Skill</div>
            </div>
            <div className="large-3 medium-3 small-3 cell text-center p-5 bg-eee">
                <div className="font-source-sans font-standard font-weight-600">Quantity</div>
            </div>
            <div className="large-3 medium-3 small-3 cell text-center p-5 bg-fafafa">
                <div className="font-source-sans font-standard font-weight-600">Skill</div>
            </div>
            <div className="large-3 medium-3 small-3 cell text-center p-5 bg-fafafa">
                <div className="font-source-sans font-standard font-weight-600">Quantity</div>
            </div>

            {formData.yourTeam.map((item, index) => (
                <React.Fragment key={item.skillLevel}>
                    {/* Your Team Skill Level Column */}
                    <div className="large-3 medium-3 small-3 cell text-center pt-10 bg-eee">{item.skillLevel > 0 ? item.skillLevel : 'Forfeit'}</div>
                    <div className="large-3 medium-3 small-3 cell text-center bg-eee">
                        <select
                            value={item.quantity}
                            onChange={(event) => handleSelectChange('yourTeam', index, event)}
                            className="small width-50px"
                        >
                            <option key="0" value="0">0</option>
                            {Array.from({ length: Math.min(5, Math.floor(23 / item.skillLevel)) }, (_, j) => j + 1).map((num) => (
                                <option key={num} value={num}>{num}</option>
                            ))}
                        </select>
                    </div>

                    {/* Opponent Skill Level Column */}
                    <div className="large-3 medium-3 small-3 cell text-center pt-10 bg-fafafa">{item.skillLevel > 0 ? item.skillLevel : 'Forfeit'}</div>
                    <div className="large-3 medium-3 small-3 cell text-center bg-fafafa">
                        <select
                            value={formData.theOpponent[index].quantity}
                            onChange={(event) => handleSelectChange('theOpponent', index, event)}
                            className="small width-50px"
                        >
                            <option key="0" value="0">0</option>
                            {Array.from({ length: Math.min(5, Math.floor(23 / item.skillLevel)) }, (_, j) => j + 1).map((num) => (
                                <option key={num} value={num}>{num}</option>
                            ))}
                        </select>
                    </div>
                </React.Fragment>
            ))}

            {teamTotals.yourTeam <= 23 ? (
                <div className="large-6 medium-6 small-6 cell text-center p-10 bt1-ccc bg-eee">
                    <div className="font-source-sans font-standard font-weight-600">Total = {teamTotals.yourTeam}</div>
                </div>
            ) : (
                <div className="large-6 medium-6 small-6 cell text-center p-10 bt1-ccc bg-red">
                    <div className="font-source-sans font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                </div>
            )}
            {teamTotals.theOpponent <= 23 ? (
                <div className="large-6 medium-6 small-6 cell text-center p-10 bt1-ccc bg-fafafa">
                    <div className="font-source-sans font-standard font-weight-600">Total = {teamTotals.theOpponent}</div>
                </div>
            ) : (
                <div className="large-6 medium-6 small-6 cell text-center p-10 bt1-ccc bg-red">
                    <div className="font-source-sans font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                </div>
            )}

            {formData.matchUps.length > 0 &&
                <>
                    <div className="large-6 medium-6 small-6 cell text-center txt-000 bg-light-blue bt1-ccc p-10">
                        <div className="font-source-sans font-standard font-weight-600">Complete Matches</div>
                        {/* Generate messages for yourTeam */}
                        {generateMessages("yourTeam", formData.yourTeam)}
                    </div>
                    <div className="large-6 medium-6 small-6 cell text-center txt-000 bg-light-blue bt1-ccc p-10">
                        <div className="font-source-sans font-standard font-weight-600">Complete Matches</div>
                        {/* Generate messages for theOpponent */}
                        {generateMessages("theOpponent", formData.theOpponent)}
                    </div>
                </>
            }
        </div>
    );
}

export default Feature2Step1;