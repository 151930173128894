import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import chevron icons

import AuthUtility from '../frontend/auth/AuthUtility';

import $ from "jquery";
import swal from 'sweetalert';

function Feature2Step3({ formData, onChange, ppmMatrixData }) {
    const [availablePlayers, setAvailablePlayers] = useState({ yourTeam: [], theOpponent: [] });
    const [selectedMatch, setSelectedMatch] = useState({ yourTeamsPlayer: null, theOpponentsPlayer: null });
    const [teamTotals, setTeamTotals] = useState({ yourTeam: 0, theOpponent: 0 });
    const [message, setMessage] = useState('');
    const [round, setRound] = useState(0);
    const [roundUpdated, setRoundUpdated] = useState(false);
    const [teamPutsUpNext, setTeamPutsUpNext] = useState('');
    const [teamPutsUpNextUpdated, setTeamPutsUpNextUpdated] = useState(false);

    const [allSenarioResults, setAllSenarioResults] = useState([]);
    const [playerAverages, setPlayerAverages] = useState([]);
    const [suggestedPlayer, setSuggestedPlayer] = useState({ yourTeamsPlayer: null, highestAverage: null });

    const [hoveredRow, setHoveredRow] = useState(null);
    const [hoveredCol, setHoveredCol] = useState(null);

    const [isPPMOpen, setIsPPMOpen] = useState(false); // Track open/close state

    const togglePPMDiv = () => {
        setIsPPMOpen(prevState => !prevState);
    };

    const calculateTeamTotal = (formData) => {
        return formData.reduce((sum, item, index) => sum + Number(item.skillLevel || 0) * Number(item.quantity || 0), 0);
    };
    const getAvailablePlayers = (formData) => {
        const availablePlayers = { yourTeam: [], theOpponent: [] };

        ['yourTeam', 'theOpponent'].forEach((team) => {
            formData[team].forEach((playerLevel, index) => {
                if (playerLevel.quantity > 0) {
                    // Create player objects and remove `quantity` and `completeMatches`
                    const players = Array.from({ length: playerLevel.quantity }, (_, i) => {
                        const { quantity, completeMatches, ...rest } = playerLevel; // Destructure to exclude properties
                        return { ...rest, instance: i }; // Include the remaining properties plus `instance`
                    });
                    availablePlayers[team].push(...players); // Spread the players into the team array
                }
            });
        });

        return availablePlayers;
    };

    useEffect(() => {

        const yourTeamTotal = calculateTeamTotal(formData.yourTeam);
        const theOpponentTotal = calculateTeamTotal(formData.theOpponent);
        const availablePlayers = getAvailablePlayers(formData)

        setTeamTotals({ yourTeam: yourTeamTotal, theOpponent: theOpponentTotal });

        setAvailablePlayers({ yourTeam: availablePlayers.yourTeam, theOpponent: availablePlayers.theOpponent });

        if (yourTeamTotal > 23) {
            alert('Your Team total exceeds 23!');
        }
        if (theOpponentTotal > 23) {
            alert('Your Opponent total exceeds 23!');
        }

        const newRound = formData.matchUps.length + 1; // Determine the round based on matchUps size
        setRound(newRound);
        setRoundUpdated(true); // Mark as updated

        if (formData.selectedTeam === 'yourTeam') {//round 1,3,5
            if (newRound % 2 === 0) {//even
                setTeamPutsUpNext('theOpponentPutsUpNext');
            } else {
                setTeamPutsUpNext('yourTeamPutsUpNext');
            }
            setTeamPutsUpNextUpdated(true);
        } else if (formData.selectedTeam === 'theOpponent') {
            if (newRound % 2 === 0) {//even
                setTeamPutsUpNext('yourTeamPutsUpNext');
            } else {
                setTeamPutsUpNext('theOpponentPutsUpNext');
            }
            setTeamPutsUpNextUpdated(true);
        }

        generateTeamExpectedScores(availablePlayers);

    }, [formData.yourTeam, formData.theOpponent, formData.selectedTeam, formData.matchUps, selectedMatch]);

    useEffect(() => {

        if ((roundUpdated && round > 0) || teamPutsUpNextUpdated) {
            updateMessage();
        }

    }, [formData, round, roundUpdated, teamPutsUpNextUpdated]);

    const getPermutations = (arr) => {
        if (arr.length <= 1) return [arr];
        let result = [];

        for (let i = 0; i < arr.length; i++) {
            const current = arr[i];
            const remaining = arr.slice(0, i).concat(arr.slice(i + 1));
            const remainingPermutations = getPermutations(remaining);

            for (const perm of remainingPermutations) {
                result.push([current, ...perm]);
            }
        }
        return result;
    };

    //Calculate team expected score
    const getValueFromPPMMatrix = (ourPlayer, opponentPlayer) => {
        const rowIndex = ppmMatrixData.findIndex(row => row[0] === ourPlayer);
        const colIndex = ppmMatrixData[0].indexOf(opponentPlayer);
        if (rowIndex > 0 && colIndex > 0) {
            return parseFloat(ppmMatrixData[rowIndex][colIndex]) || 0;
        }
        return 0;
    };

    const generateTeamExpectedScores = (availablePlayers) => {

        const yourTeamsRoster = [];
        for (let i = 0; i < availablePlayers.yourTeam.length; i++) {
            yourTeamsRoster.push(availablePlayers.yourTeam[i].skillLevel + availablePlayers.yourTeam[i].plusMinus);
        }
        const theOpponentsRoster = [];
        for (let i = 0; i < availablePlayers.theOpponent.length; i++) {
            theOpponentsRoster.push(availablePlayers.theOpponent[i].skillLevel + availablePlayers.theOpponent[i].plusMinus);
        }

        const allScenarios = getPermutations(yourTeamsRoster);
        let allSenarioResults = [];

        allScenarios.forEach((scenario) => {
            let yourTeamsScore = 0;
            let theOpponentsScore = 0;
            let individualMatchups = [];

            scenario.forEach((yourTeamsPlayer, index) => {
                const theOpponentsPlayer = theOpponentsRoster[index];
                const theOpponentsPlayerScore = getValueFromPPMMatrix(theOpponentsPlayer, yourTeamsPlayer) || 0;
                theOpponentsScore += theOpponentsPlayerScore;

                const yourTeamsPlayerScore = getValueFromPPMMatrix(yourTeamsPlayer, theOpponentsPlayer) || 0;
                yourTeamsScore += yourTeamsPlayerScore;

                individualMatchups.push({ yourTeamsPlayer, yourTeamsPlayerScore: yourTeamsPlayerScore.toFixed(2), theOpponentsPlayer, theOpponentsPlayerScore: theOpponentsPlayerScore.toFixed(2) });
            });

            allSenarioResults.push({ scenario, individualMatchups, yourTeamsScore: yourTeamsScore.toFixed(2), theOpponentsScore: theOpponentsScore.toFixed(2) });
        });

        allSenarioResults.sort((a, b) => b.yourTeamsScore - a.yourTeamsScore);
        setAllSenarioResults(allSenarioResults);
    };

    const calculateYourTeamAverage = (yourPlayer, theOpponentPlayer) => {
        let totalScore = 0;
        let count = 0;

        allSenarioResults.forEach(result => {
            result.individualMatchups.forEach(matchup => {

                if (matchup.yourTeamsPlayer === yourPlayer && matchup.theOpponentsPlayer === theOpponentPlayer) {
                    totalScore += Number(result.yourTeamsScore);
                    count++;
                }

            })
        });

        return (totalScore / count).toFixed(2);
    }
    const findBestMatchup = (theOpponentPlayer) => {
        let yourTeamsBestPlayer = null;
        let highestAverage = -Infinity;

        availablePlayers.yourTeam.forEach(yourTeamsPlayer => {
            let yourTeamsAverage = calculateYourTeamAverage(yourTeamsPlayer.skillLevel + yourTeamsPlayer.plusMinus, theOpponentPlayer.skillLevel + theOpponentPlayer.plusMinus);
            setPlayerAverages(prevPlayerAverages => [...prevPlayerAverages, { yourTeamsPlayer: yourTeamsPlayer.skillLevel + yourTeamsPlayer.plusMinus, yourTeamsAverage: yourTeamsAverage }]);
            if (yourTeamsAverage > highestAverage) {
                highestAverage = Number(yourTeamsAverage).toFixed(2);
                yourTeamsBestPlayer = yourTeamsPlayer;
            }
        });

        return { yourTeamsBestPlayer: yourTeamsBestPlayer, highestAverage: highestAverage };
    };

    const isPlayerMatched = (team, skillLevel, instance) => {
        return formData.matchUps.some(
            (match) => match.isComplete && match[team] && match[team].skillLevel === skillLevel && match[team].instance === instance
        );
    };

    const handleSelect = (team, player) => {
        setSelectedMatch((prev) => ({
            ...prev,
            [team]: player,
        }));

        setPlayerAverages([]);

        if (teamPutsUpNext === 'yourTeamPutsUpNext') {

            setTeamPutsUpNext('theOpponentPutsUpNext');
            setTeamPutsUpNextUpdated(true);
        } else if (teamPutsUpNext === 'theOpponentPutsUpNext') {
            setTeamPutsUpNext('yourTeamPutsUpNext');
            setTeamPutsUpNextUpdated(true);

            const { yourTeamsBestPlayer, highestAverage } = findBestMatchup(player);
            setSuggestedPlayer({ yourTeamsPlayer: yourTeamsBestPlayer, highestAverage: highestAverage });
        }
        //message will be updated through the useEffect
    };

    const handleClearMatch = () => {

        setSelectedMatch({ yourTeamsPlayer: null, theOpponentsPlayer: null });

        setPlayerAverages([]);
        setSuggestedPlayer([]);

        //teamPutsUpNext and message will be updated through the useEffect
    };

    const handlePlusMinusChange = (team, player, event) => {
        const updatedData = { plusMinus: event.target.value };

        setAvailablePlayers((prevData) => ({
            ...prevData,
            [team.replace("sPlayer", "")]: prevData[team.replace("sPlayer", "")].map((item, i) =>
                item.skillLevel === player.skillLevel && item.instance === player.instance ? { ...item, ...updatedData } : item
            ),
        }));
    };

    const handleCompleteMatch = () => {
        if (selectedMatch.yourTeamsPlayer && selectedMatch.theOpponentsPlayer) {

            const updatedFormData = {
                ...formData,
                matchUps: [
                    ...formData.matchUps,
                    { yourTeamsPlayer: selectedMatch.yourTeamsPlayer, theOpponentsPlayer: selectedMatch.theOpponentsPlayer, isComplete: true },
                ],
                yourTeam: formData.yourTeam.map((player) =>
                    player.skillLevel === selectedMatch.yourTeamsPlayer.skillLevel
                        ? { ...player, completeMatches: Number(player.completeMatches + 1) }
                        : player
                ),
                theOpponent: formData.theOpponent.map((player) =>
                    player.skillLevel === selectedMatch.theOpponentsPlayer.skillLevel
                        ? { ...player, completeMatches: Number(player.completeMatches + 1) }
                        : player
                ),
            };
            onChange(updatedFormData);

            setSelectedMatch({ yourTeamsPlayer: null, theOpponentsPlayer: null });

            setPlayerAverages([]);
            setSuggestedPlayer([]);
        }
    };

    function updateMessage() {

        if (availablePlayers.yourTeam.length > 0 && availablePlayers.theOpponent.length > 0) {
            if (formData.selectedTeam === 'yourTeam' || formData.selectedTeam === 'theOpponent') {
                if (round <= 5) {
                    if (selectedMatch.yourTeamsPlayer && selectedMatch.theOpponentsPlayer) {
                        setMessage('Rnd ' + round + ': When the match is over click the button below');
                        $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green bg-light-gold').addClass('txt-000 bg-light-blue');
                    } else if (teamPutsUpNext === 'yourTeamPutsUpNext') {
                        setMessage('Rnd ' + round + ': Your team puts up next.');
                        $('#message').removeClass('txt-333 txt-000 bg-light-red bg-light-blue bg-light-gold').addClass('txt-fff bg-light-green');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                        $('#theOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                    } else if (teamPutsUpNext === 'theOpponentPutsUpNext') {
                        setMessage('Rnd ' + round + ': Your opponent puts up next.');
                        $('#message').removeClass('txt-333 txt-fff bg-light-green bg-light-blue bg-light-gold').addClass('txt-000 bg-light-red');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                        $('#theOpponentUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                    } else {
                        setMessage('Warning - Something went wrong. Needs debugging.');
                        $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green bg-light-blue').addClass('txt-000 bg-light-gold');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                        $('#theOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                    }
                } else {
                    setMessage('All Matches Complete');
                    $('#message').removeClass('txt-333 txt-000 bg-light-red bg-light-green bg-light-blue').addClass('txt-fff bg-dark-blue');
                }
            } else {
                setMessage('Warning - Steps 2 needs to be filled out.');
                $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green bg-light-blue').addClass('txt-000 bg-light-gold');
                $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                $('#theOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
            }
        } else {
            setMessage('Warning - Steps 1 needs to be filled out.');
            $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green bg-light-blue').addClass('txt-000 bg-light-gold');
            $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
            $('#theOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
        }

        setRoundUpdated(false);
        setTeamPutsUpNextUpdated(false);
    }

    const handleMouseEnter = (rowIndex, colIndex) => {
        if (rowIndex > 0 && colIndex > 0) { // Ignore headers
            setHoveredRow(rowIndex);
            setHoveredCol(colIndex);
        }
    };

    const handleMouseLeave = () => {
        setHoveredRow(null);
        setHoveredCol(null);
    };

    return (
        <div className="grid-x b1-ccc">
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-eee">
                <div className="font-source-sans font-standard font-weight-600">Your Team</div>
            </div>
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-fafafa">
                <div className="font-source-sans font-standard font-weight-600">The Opponent</div>
            </div>
            {AuthUtility.isSubscribed() ? (
                <>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-eee">
                        <div className="font-source-sans font-standard font-weight-600">Skill</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-eee">
                        <div className="font-source-sans font-standard font-weight-600">- / +</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-eee">
                        <div className="font-source-sans font-standard font-weight-600">Player</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-fafafa">
                        <div className="font-source-sans font-standard font-weight-600">Skill</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-fafafa">
                        <div className="font-source-sans font-standard font-weight-600">- / +</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-fafafa">
                        <div className="font-source-sans font-standard font-weight-600">Player</div>
                    </div>
                </>
            ) : (
                <>
                    <div className="large-3 medium-3 small-3 cell text-center p-5 bg-eee">
                        <div className="font-source-sans font-standard font-weight-600">Skill</div>
                    </div>
                    <div className="large-3 medium-3 small-3 cell text-center p-5 bg-eee">
                        <div className="font-source-sans font-standard font-weight-600">Player</div>
                    </div>
                    <div className="large-3 medium-3 small-3 cell text-center p-5 bg-fafafa">
                        <div className="font-source-sans font-standard font-weight-600">Skill</div>
                    </div>
                    <div className="large-3 medium-3 small-3 cell text-center p-5 bg-fafafa">
                        <div className="font-source-sans font-standard font-weight-600">Player</div>
                    </div>
                </>
            )
            }
            <div id="yourTeamUI" className="large-6 medium-6 small-6 cell text-center bb1-ccc bg-eee">
                <div className="grid-x">
                    {availablePlayers.yourTeam.map((player, index) => (
                        AuthUtility.isSubscribed() ? (
                            <React.Fragment key={`yourTeam-${player.skillLevel}-${player.instance}`}>
                                <div className="large-4 medium-4 small-4 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.plusMinus}</div>
                                <div className="large-4 medium-4 small-4 cell text-center pt-5">
                                    <select
                                        onChange={(event) => handlePlusMinusChange('yourTeamsPlayer', player, event)}
                                        className="small width-35px"
                                    >
                                        <option key="n" value="n">n</option>
                                        <option key="+" value="+">+</option>
                                        <option key="-" value="-">-</option>
                                    </select>
                                </div>
                                <div className="large-4 medium-4 small-4 cell text-center player-checkbox">
                                    <input type="checkbox" name={"yourTeam_" + index} id={"yourTeam_" + index} value="1"
                                        checked={
                                            selectedMatch.yourTeamsPlayer &&
                                            selectedMatch.yourTeamsPlayer.skillLevel === player.skillLevel &&
                                            selectedMatch.yourTeamsPlayer.instance === player.instance
                                        }
                                        onChange={() => handleSelect('yourTeamsPlayer', player)}
                                        disabled={isPlayerMatched('yourTeamsPlayer', player.skillLevel, player.instance)}
                                    />
                                    <label htmlFor={"yourTeam_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment key={`yourTeam-${player.skillLevel}-${player.instance}`}>
                                <div className="large-6 medium-6 small-6 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.plusMinus}</div>
                                <div className="large-6 medium-6 small-6 cell text-center player-checkbox">
                                    <input type="checkbox" name={"yourTeam_" + index} id={"yourTeam_" + index} value="1"
                                        checked={
                                            selectedMatch.yourTeamsPlayer &&
                                            selectedMatch.yourTeamsPlayer.skillLevel === player.skillLevel &&
                                            selectedMatch.yourTeamsPlayer.instance === player.instance
                                        }
                                        onChange={() => handleSelect('yourTeamsPlayer', player)}
                                        disabled={isPlayerMatched('yourTeamsPlayer', player.skillLevel, player.instance)}
                                    />
                                    <label htmlFor={"yourTeam_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                                </div>
                            </React.Fragment>
                        )
                    ))}

                    {teamTotals.yourTeam <= 23 ? (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-eee">
                            <div className="font-source-sans font-standard font-weight-600">Total = {teamTotals.yourTeam}</div>
                        </div>
                    ) : (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-red">
                            <div className="font-source-sans font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                        </div>
                    )}
                </div>
            </div>
            <div id="theOpponentUI" className="large-6 medium-6 small-6 cell text-center bb1-ccc bg-fafafa">
                <div className="grid-x">
                    {availablePlayers.theOpponent.map((player, index) => (
                        AuthUtility.isSubscribed() ? (
                            <React.Fragment key={`theOpponent-${player.skillLevel}-${player.instance}`}>
                                <div className="large-4 medium-4 small-4 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.plusMinus}</div>
                                <div className="large-4 medium-4 small-4 cell text-center pt-5">
                                    <select
                                        onChange={(event) => handlePlusMinusChange('theOpponentsPlayer', player, event)}
                                        className="small width-35px"
                                    >
                                        <option key="n" value="n">n</option>
                                        <option key="+" value="+">+</option>
                                        <option key="-" value="-">-</option>
                                    </select>
                                </div>
                                <div className="large-4 medium-4 small-4 cell text-center player-checkbox">
                                    <input type="checkbox" name={"theOpponent_" + index} id={"theOpponent_" + index} value="1"
                                        checked={
                                            selectedMatch.theOpponentsPlayer &&
                                            selectedMatch.theOpponentsPlayer.skillLevel === player.skillLevel &&
                                            selectedMatch.theOpponentsPlayer.instance === player.instance
                                        }
                                        onChange={() => handleSelect('theOpponentsPlayer', player)}
                                        disabled={isPlayerMatched('theOpponentsPlayer', player.skillLevel, player.instance)}
                                    />
                                    <label htmlFor={"theOpponent_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                                </div>
                            </React.Fragment>
                        ) : (

                            <React.Fragment key={`theOpponent-${player.skillLevel}-${player.instance}`}>
                                <div className="large-6 medium-6 small-6 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.plusMinus}</div>
                                <div className="large-6 medium-6 small-6 cell text-center player-checkbox">
                                    <input type="checkbox" name={"theOpponent_" + index} id={"theOpponent_" + index} value="1"
                                        checked={
                                            selectedMatch.theOpponentsPlayer &&
                                            selectedMatch.theOpponentsPlayer.skillLevel === player.skillLevel &&
                                            selectedMatch.theOpponentsPlayer.instance === player.instance
                                        }
                                        onChange={() => handleSelect('theOpponentsPlayer', player)}
                                        disabled={isPlayerMatched('theOpponentsPlayer', player.skillLevel, player.instance)}
                                    />
                                    <label htmlFor={"theOpponent_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                                </div>
                            </React.Fragment>
                        )
                    ))}

                    {teamTotals.theOpponent <= 23 ? (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-fafafa">
                            <div className="font-source-sans font-standard font-weight-600">Total = {teamTotals.theOpponent}</div>
                        </div>
                    ) : (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-red">
                            <div className="font-source-sans font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                        </div>
                    )}
                </div>
            </div>
            {suggestedPlayer && suggestedPlayer.yourTeamsPlayer && suggestedPlayer.yourTeamsPlayer.skillLevel ? (
                <div className="large-12 medium-12 small-12 cell bg-fff txt-fff text-center p-10" id="message">
                    <span className="font-source-sans font-medium font-weight-600">{message}</span>
                    &nbsp;&nbsp;&nbsp;
                    <span className="font-source-sans font-medium font-weight-600">Suggestion: {suggestedPlayer.yourTeamsPlayer.skillLevel + suggestedPlayer.yourTeamsPlayer.plusMinus}</span>

                    <br />
                    {/*playerAverages.map((entry, index) => (
                        <div key={index} className="font-source-sans font-medium font-weight-600">With &nbsp; {entry.yourTeamsPlayer} &nbsp;=&nbsp; {entry.yourTeamsAverage} &nbsp; average</div>
                    ))*/}

                    {playerAverages
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => b.yourTeamsAverage - a.yourTeamsAverage) // Sort in descending order
                        .map((entry, index) => (
                            <div key={index} className="font-source-sans font-medium font-weight-600">
                                With &nbsp; {entry.yourTeamsPlayer} &nbsp;=&nbsp; {entry.yourTeamsAverage} &nbsp; average
                            </div>
                        ))}
                </div>
            ) : (
                <div className="large-12 medium-12 small-12 cell bg-fff txt-333 text-center p-10" id="message">
                    <span className="font-source-sans font-medium font-weight-600">{message}</span>
                </div>
            )}

            <div className="large-12 medium-12 small-12 cell text-center bb1-ccc bg-fff ptb-10 plr-5">
                <Link onClick={handleClearMatch} disabled={!selectedMatch.yourTeamsPlayer && !selectedMatch.theOpponentsPlayer} className="button auto">
                    Clear Match
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link onClick={handleCompleteMatch} disabled={!selectedMatch.yourTeamsPlayer || !selectedMatch.theOpponentsPlayer} className="button auto">
                    Match Complete
                </Link>
            </div>

            {/*
            <div className="large-12 medium-12 small-12 cell text-center bb1-333x bg-fff ptb-10">
                {ppmMatrixData ? (
                    <table border="0" cellPadding="4" style={{ borderCollapse: "collapse", margin: "auto" }}>
                        <tbody>
                            {ppmMatrixData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, colIndex) => {
                                        const isHovered = hoveredRow !== null && hoveredCol !== null && ((rowIndex === hoveredRow && colIndex <= hoveredCol) || (colIndex === hoveredCol && rowIndex <= hoveredRow));
                                        return (
                                            <td
                                                key={colIndex}
                                                style={{
                                                    textAlign: "center",
                                                    padding: "4px",
                                                    backgroundColor: isHovered ? "lightgreen" : "inherit",
                                                    color: isHovered ? "black" : "inherit",
                                                    fontWeight: (rowIndex === hoveredRow && colIndex === hoveredCol) ? "600" : "300",
                                                    cursor: (rowIndex > 0 && colIndex > 0) ? "pointer" : "default"
                                                }}
                                                onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                {cell === "Forfeit" ? "F" : cell}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
*/}
            <div id="ppm" className="large-12 medium-12 small-12 cell text-center">
                <div style={{ maxWidth: "300px", margin: "0 auto", textAlign: "center" }}>
                    <button onClick={togglePPMDiv} style={{ display: "flex", alignItems: "center", gap: "5px", padding: "10px", fontSize: "16px", cursor: "pointer" }}>
                        {isPPMOpen ? "PPM Close" : "PPM Open"} {isPPMOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                    {isPPMOpen && (
                        allSenarioResults.map((matchup, index) => (
                            <div key={index} className="ptb-5 bt1-ccc">
                                {
                                    matchup.individualMatchups.map((match, idx) => (
                                        <div key={index + "_" + idx}>
                                            Your Player {match.yourTeamsPlayer} = {match.yourTeamsPlayerScore} &nbsp;&nbsp;&nbsp; The Opponent {match.theOpponentsPlayer} = {match.theOpponentsPlayerScore}
                                        </div>
                                    ))
                                }
                                <span className="text-cener font-weight-600">Your Team = {matchup.yourTeamsScore} &nbsp;&nbsp;&nbsp; The Opponent = {matchup.theOpponentsScore}</span>
                            </div>
                        ))
                    )}
                </div>
            </div>

        </div>
    );
}

export default Feature2Step3;